<template>
    <el-dialog :close-on-click-modal="false"  
      title="扫码枪匹配"
      :visible.sync="visible"
      width="540px"
      class="set_device"
      :before-close="handleClose"
      
    >
      <el-form
        :rules="scanRules"
        :model="scanForm"
        ref="scanForm"
        label-width="100px"
      >
        <!-- <el-form-item prop="factory" label="工厂账号">
          <el-select
            clearable
            v-model="scanForm.factory"
            placeholder="请选择工厂账号"
            size="small"
            filterable
            style="width: 214px;"
          >
            <el-option
              v-for="(value, index) in dictData['factory_account']"
              :key="index"
              :label="value.label"
              :value="value.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="sign" label="标识">
          <el-input size="small" v-model="scanForm.sign" clearable style="width: 214px;"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">返回</el-button>
        <el-button type="primary" @click="submitScanInfo" size="small">
          保存
        </el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  import { mapGetters } from "vuex"
  export default {
    name: "SetDevice",
    data() {
      return {
        scanForm: {
            factory: 'factory_notify',
            sign: '',
        },
        scanRules: {
            // factory: [{ required: true, message: "请选择工厂账号", trigger: "change" }],
            sign: [{ required: true, message: "请输入标识", trigger: "change" }],
        },
        prevStr: '',
      }
    },
    props: {
      visible: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
    watch: {
        visible(v) {
            if (v) {
                const info = localStorage.getItem('scanInfo')
                if (info) {
                    this.prevStr = info
                    const obj = JSON.parse(info)
                    // this.scanForm.factory = obj.factory
                    this.scanForm.sign = obj.sign
                }
            }
        }
    },
    computed: {
        ...mapGetters(["dictData"]),
    },
    methods: {
      submitScanInfo() {
        this.$refs.scanForm.validate((valid) => {
            if (valid) {
            const obj = {
                factory: this.scanForm.factory,
                sign: this.scanForm.sign,
            }
            const str = JSON.stringify(obj)
            let flag = false
            if (str != this.prevStr) {
                flag = true
            }
            localStorage.setItem('scanInfo',str)
            this.$emit("onClose", flag)
            }
        })
      },
      handleClose() {
        this.$emit("onClose")
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .set_device {

  }
  </style>
  